@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

body, html{
  font-family: 'Poppins', sans-serif;
  height: 100%;
}

.aqua{
  color: aqua;
}

.primaire{
  background-color: #455a64;
}

a.nav-link.lien, a.nav-link:hover, a.nav-link:focus{
  color: aqua;
}

a.nav-link{
  font-size: small;
  color: white;
}

.nav-item .tabActif{
  text-decoration: none;
  font-weight: bold;
}

.nav-item .tabInactif{
  text-decoration: none;
  color: #644545;
}

.bordure-gauche{
  border-left:10px solid aqua;
}




